<template>
  <v-container fluid class="fill-height fundo">
    <v-layout align-center justify-center>

      <v-overlay :value="logandoAutomaticamente"
                 :opacity="0.2">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-form class="container"
              @submit.prevent="login" v-show="!logandoAutomaticamente">
        <v-card
            max-width="440px"
            min-height="516px"
            class="mx-auto"
            :loading="carregando">
          <v-card-subtitle class="mx-4 text-center">
            <v-row>
              <v-col>
                <v-img src="@/assets/policia.png" width="98px" class="mx-auto"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>Polícia Civil do Estado do Pará</v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-title class="mx-4 text-center">
            <v-icon>mdi-flash</v-icon>
            {{ sistema }}
          </v-card-title>

          <v-card-text v-show="exibirLogin">
            <v-row class="px-4">
              <v-col>
                <v-text-field label="Digite seu email"
                              outlined
                              v-model="form.email"
                              tabindex="1"></v-text-field>
              </v-col>
            </v-row>

            <v-row class="px-4" dense>
              <v-col>
                <v-text-field
                    label="Digite sua senha"
                    :append-icon="tela.exibirCaps ? 'mdi-alert' : ''"
                    outlined type="password"
                    v-model="form.senha"
                    @keyup="verificarCaps"
                    tabindex="2">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="px-4 mt-n8">
              <v-col cols="12">
                <v-btn
                    small
                    text
                    class="text-none"
                    @click="exibirLogin = false"
                    color="primary">Esqueceu e-mail ou senha?
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <Mensagem
              :mensagem="tela.mensagemApi"
              v-show="tela.mensagemApi && exibirLogin"
          ></Mensagem>

          <v-card-actions class="pb-8" v-show="exibirLogin">
            <v-row>
              <!--            <v-col offset="1">-->
              <!--              <v-btn-->
              <!--                  class="text-none"-->
              <!--                  small-->
              <!--                  to="/solicitar-acesso"-->
              <!--                  text>Criar conta-->
              <!--              </v-btn>-->
              <!--            </v-col>-->
              <v-col offset="8">
                <v-btn class="primary text-none"
                       type="submit"
                       :disabled="carregando"
                       tabindex="3">Entrar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

          <!-- Esqueci a senha  -->
          <v-card-text v-show="!exibirLogin">
            <v-col>
              <v-text-field
                  label="Digite seu e-mail ou CPF"
                  v-model="form.email"
                  outlined></v-text-field>
            </v-col>
          </v-card-text>

          <Mensagem
              :mensagem="tela.mensagemApi"
              v-show="tela.mensagemApi && !exibirLogin"
          ></Mensagem>

          <v-card-actions
              class="pb-8"
              v-show="!exibirLogin">
            <v-row>
              <v-col>
                <v-btn
                    class="ml-4"
                    @click="botaoVoltar"
                    text>voltar
                </v-btn>
              </v-col>

              <v-spacer/>
              <v-col>
                <v-btn class="primary text-none"
                       type="submit"
                       :disabled="carregando"
                       tabindex="3">Enviar
                </v-btn>
              </v-col>


            </v-row>
          </v-card-actions>


        </v-card>
      </v-form>
    </v-layout>
  </v-container>


  <!--  </v-overlay>-->
</template>

<script>
import api from '../api'
import {abrirSessaoOuUrl} from "../seguranca/helpers";
import Mensagem from "@/components/Mensagem"

export default {
  name: "TelaLogin",
  components: {
    Mensagem
  },
  data() {
    return {
      carregando: false,
      exibirLogin: true,
      form: {
        email: '',
        senha: '',
      },
      logandoAutomaticamente: true,
      tela: {
        exibirCaps: false,
        mensagemApi: ''
      }
    }
  },
  computed: {
    sistema() {
      return process.env.VUE_APP_NOME_SISTEMA
    }
  },
  methods: {
    botaoVoltar() {
      this.tela.mensagemApi = ''
      this.form.email = ''
      this.form.senha = ''
      this.exibirLogin = true
    },
    login() {
      if (this.exibirLogin)
        this.autenticar()
      else
        this.enviarEmail()
    },
    autenticar() {
      this.carregando = true;
      this.tela.mensagemApi = ''
      api.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)
          .then(() => {

            api.post('/login', this.form)
                .then(r => {
                  this.$store.dispatch('registrarUsuario', r.data)

                  //o catch abaixo evita waning no console (se vue guard mudar a rota)
                  // this.$router.push('/home').catch(() => {});
                  if (r.data.cadastro_incompleto) {
                    this.$router.push('/configuracoes');
                    return;
                  }
                  abrirSessaoOuUrl('/home')
                })
                .catch((e) => {
                  if (e.isAxiosError && e.response.status === 401) {
                    this.$store.commit('LIMPAR_TUDO')
                  }

                  this.tela.mensagemApi = e
                })
                .finally(() => {
                  this.carregando = false
                })
          })
          .catch(() => {
            this.carregando = false
          })
    },
    enviarEmail() {
      this.carregando = true
      api.post('usuario/enviar-email', {
        email: this.form.email
      })
          .then(r => {
            this.tela.mensagemApi = r
          })
          .catch(e => {
            this.tela.mensagemApi = e
          })
          .finally(() => {
            this.carregando = false
          });
    },
    verificarCaps(e) {
      if (typeof e.getModifierState !== 'function')
        return
      this.tela.exibirCaps = e.getModifierState('CapsLock');
    }
  },
  beforeCreate() {
    // if (isUsuarioAutenticado()) {
    //   this.logandoAutomaticamente = false;
    //   abrirSessaoOuUrl('/home');
    // }
    if (this.$store.state.usuario.nome)
      return;

    this.logandoAutomaticamente = true;

    api.get('/usuario/info')
        .then(r => {
          // this.$store.commit('ATUALIZAR_USUARIO_NOME', r.data.nome);
          this.$store.dispatch('registrarUsuario', r.data)

          abrirSessaoOuUrl('/home')

        })
        .catch((e) => {
          if (e.isAxiosError && e.response.status === 401) {
            this.$store.commit('LIMPAR_TUDO')
          }

        })
        .finally(() => {
          this.logandoAutomaticamente = false;
        })
  },
  mounted() {
    this.logandoAutomaticamente = true

  }
}
</script>

<style scoped>
.fundo {
  height: 100%;
  background: #272b30; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(#D3D3D3, #808080, #272b30); /* Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#D3D3D3, #808080, #272b30); /* For Opera 11.6 to 12.0 */
  background: -moz-radial-gradient(#D3D3D3, #808080, #272b30); /* For Firefox 3.6 to 15 */
  background: radial-gradient(#D3D3D3, #808080, #272b30); /* Standard syntax (must be last) */
}
</style>