import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//as constantes abaixo são truques para tornar mais simples recriar o estado inicial da sessão (quando usuário faz logout)
const getDefaultStateInstance = () => {//este método cria o modelo de sessão vazia
    return {
        tela: {
            linkPesquisa: '',
        },
        novaAcao: {
            modal: false,
            depende: true,
            uri: '',
            method: ''
        },
        usuario: {
            nome: '',
            rotas: [],
            menus: [],
            obrigatorio: [
                '/',
                '/autenticacao',
                '/sem-permissao',
                '/solicitar-acesso',
                '/atualizar-senha',
                '/configuracoes',
                '/configuracoes/senha'
            ],
            // token: ''
        },
        dependencia: {},
        totalDependencia: 0
    }
}

const state = getDefaultStateInstance();//pro vuex o estado inicial deve se chamar state e ser um objeto

export default new Vuex.Store({
    state,
    mutations: {
        /*
         * São convenções mutations:
         * - Estar em caixa alta
         * - Entre as palavras colocar _
         * - Apenas elas devem modificar um state. Actions não devem fazer isto.
         */
        ATUALIZAR_LINK_PESQUISA(state, payload) {
            state.tela.linkPesquisa = payload
        },
        ATUALIZAR_USUARIO_NOME(state, payload) {
            state.usuario.nome = payload
        },
        ATUALIZAR_USUARIO_TOKEN(state, payload) {
            state.usuario.token = payload
        },
        ATUALIZAR_ROTAS(state, payload) {
            // state.usuario.rotas = Array.isArray(payload.rotas) ? payload.rotas : []
            state.usuario.rotas = payload
        },
        ATUALIZAR_MENU(state, payload) {
            state.usuario.menus = payload
        },
        LIMPAR_TUDO() {
            //chama o método que gera um modelo de sessão vazia (recriando o estado inicial)
            Object.assign(state, getDefaultStateInstance())
            window.sessionStorage.removeItem('url')
        },
        INCLUIR_DEPENDENCIA(state, payload) {
            state.dependencia = payload
            state.totalDependencia++
        },
        LIMPAR_DEPENDENCIA(state) {
            state.dependencia = {}
            state.totalDependencia = 0
        }
    },
    actions: {
        registrarUsuario(context, payload) {
            context.commit("ATUALIZAR_USUARIO_NOME", payload.nome)
            context.commit("ATUALIZAR_ROTAS", payload.rotas)
            context.commit("ATUALIZAR_MENU", payload.menus)
        },
    },
    getters: {
        usuarioAdministrador(state) {
            return state.usuario.rotas[0] === '*'
        }
    },
    modules: {}
})
