import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import portugues from 'vuetify/lib/locale/pt'

export default new Vuetify({
  lang: {
    locales: { portugues },
    current: 'portugues'
  }
});
