<template>
  <v-container>
    <v-card>
      <v-card-title><h1>Página não encontrada</h1></v-card-title>
      <v-card-actions>
        <v-btn dark @click="$router.go(-1)">Voltar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "P404"
}
</script>

<style scoped>

</style>