<template>
  <v-container>
    <v-alert
        border="left"
        colored-border
        type="error"
        elevation="2"
    >
      <div class="text-h6">
        Aviso
      </div>
      Seu usuário não possui permissão para acessar o recurso solicitado.
    </v-alert>
    <v-btn @click="$router.go(-1)">Voltar</v-btn>

  </v-container>
</template>

<script>
export default {
  name: "Publico"
}
</script>

<style scoped>

</style>