import store from "../store";
import router from "../router";

/**
 * Verifica se o usuário possui permissão para acessar determinada rota.
 * As rotas que vêm do navegador (/usuario/123) e as rotas que vêm do banco (/usuario/:id) são tratadas aqui
 * para ficarem iguais.
 * Ex:
 * /usuario/123/456/002 fica /usuario/#/#/#
 * /usuario/:id/:cpf/:cidade fica /usuario/#/#/#
 *
 * @param url
 * @returns {boolean|*}
 */
function possuiPermissao(url) {
    let urlTratada = url.replaceAll(/\d+/g, '#')

    if (store.state.usuario.obrigatorio.find(element => element === url))
        return true
    else return store.state.usuario.rotas.find(element => {
        return element.replaceAll(/:id/g, '#') === urlTratada || element === '*'
    })
}

/**
 * Abre o endereço salvo na sessão
 * ou a url enviada como parâmetro
 * @param url
 */
function abrirSessaoOuUrl(url) {//usado na tela de login
    if (typeof window.sessionStorage.url !== 'undefined') {
        let endereco = window.sessionStorage.url
        window.sessionStorage.removeItem('url')
        router.push(endereco)
    } else {
        router.push(url);
    }
}

function isUsuarioAutenticado() {
    return !!store.state.usuario.nome;
}

function isUsuarioAdmin() {
    return store.state.usuario.rotas[0] === '*'
}

export {
    possuiPermissao,
    isUsuarioAutenticado,
    abrirSessaoOuUrl,
    isUsuarioAdmin
}