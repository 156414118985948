<template>
  <v-slide-x-transition mode="out-in">
    <v-alert
        class="ma-4"
        border="right"
        colored-border
        :type="corMensagem"
        elevation="1">
      <ul v-show="existeMensagem">
        <li class="estilo-lista" v-for="(m, index) in mensagemTratada" :key="index">{{ m }}</li>
      </ul>
      <slot></slot>
    </v-alert>
  </v-slide-x-transition>
</template>

<script>
export default {
  name: "Mensagem",
  props: [
    'mensagem',
    'cor',
    'tempo'
  ],
  data() {
    return {
      existeMensagem: false,
    }
  },
  watch: {
    mensagemTratada(val) {
      this.existeMensagem = val.length > 0;
    },
  },
  computed: {
    corMensagem() {
      if (typeof this.mensagem.response !== 'undefined'
          && typeof this.mensagem.response.status !== 'undefined'
          && this.mensagem.response.status !== 200) {
        return 'error';
      } else {
        return 'success';
      }
    },
    /*
     *
     * <=============================>
     *  Resposta de sucesso do axios
     *  {
     *   config: {...},
     *   data: {...},
     *   headers: {...},
     *   request: {...},
     *   status: {...},
     *   statusText: {...},
     *  }
     * <=============================>
     *
     * <=============================>
     * Resposta de erro do axios
     * {
     *  config: {...},
     *  isAxiosError: true,
     *  request: XMLHttpRequest: {...},
     *  response: {
     *      config: {...},
     *      data: {...},
     *      headers: {...},
     *      request: {...},
     *      status: {...},
     *      statusText: {...},
     *  },
     *  toJSON: {...},
     * }
     * <===============================>
     */
    mensagemTratada() {
      if (this.mensagem === '') {//inicialmente as telas mandam '' para este componente
        return [];
      }

      let msgs = [];

      if (typeof this.mensagem.isAxiosError !== 'undefined') {//resposta com erro
        let errosLaravel = this.mensagem.response.data

        /*
         * A mensagem de erro vem na raiz da resposta em uma variável chamada message.
         */
        if (typeof errosLaravel.errors === 'undefined' && typeof errosLaravel.message !== 'undefined') {
          msgs.push(errosLaravel.message);
          return msgs;
        }

        /* erros enviados pela validação laravel ficam em:
         * errors [
         *   'campo1' => [...],
         *   'campo2' => [...],
         * ]
         *
         */
        for (let i in errosLaravel.errors) {
          msgs = msgs.concat(errosLaravel.errors[i]);
        }

      } else {//respostas sem erro

        // //disparos de ajax sem corpo de resposta podem gerar erro
        // //acontece quando axios dispara método "options" para verificar cors
        // if (this.mensagem.data.length === 0) {
        //   return [];
        // }

        if (typeof this.mensagem.data !== 'undefined' &&
            typeof this.mensagem.data.message !== 'undefined') {//mensagens do laravel sempre chegam em message
          msgs.push(this.mensagem.data.message);
        } else if (typeof this.mensagem.data !== 'undefined') {//mensagens qualquer enviadas pela api
          msgs.push(this.mensagem.data);
        } else {
          msgs.push('Não foi possível identificar a resposta do servidor.');
        }
      }

      return msgs;
    },
  },
  methods: {
    expirarMensagem(tempo) {
      
      setTimeout(() => {
        console.log("Delayed for 1 second.");
      }, (tempo*1000))
    }
  }
}
</script>

<style scoped>
.estilo-lista {
  list-style-type: none;
}
</style>